import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Checkbox,
  ThemeProvider,
  Tooltip,
  useTheme,
} from '@mui/material';

import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import classNames from 'classnames';

import { ReactComponent as WrapTextIcon } from '../../assets/svg/wrapTextIcon.svg';

import { dataGridTheme } from '../../theme/mui';
import { useCommonClasses } from '../../theme/commonStyles';
import {
  determineColumnSize,
  determineColumnType,
} from '../../core/utils/uxUtils';

import {
  DataTableCustomTool,
  DataTableHeader,
  DataTableRow,
} from '../../core/types/dataTable';

import styles from './DataTable.styles';

const useStyles = makeStyles(styles);

type Props = {
  dataHeaders: DataTableHeader[];
  dataRows: DataTableRow[];
  exportFileName?: string;
  width: number;
  customTools?: DataTableCustomTool[];
};

const ENABLE_ROW_CHECKBOX = false;

const DataTable: React.FC<Props> = ({
  dataHeaders,
  dataRows,
  exportFileName,
  width,
  customTools,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isWrapText, setWrapText] = useState(true);

  const rows = dataRows.map((row, index) => ({
    ...row,
    id: index,
  }));

  const nonTextIndexes = Array.from(new Set(dataRows.flatMap((dataRow) => {
    const rowKeys = Object.keys(dataRow);
    return rowKeys.map((key) => {
      const value = dataRow[Number(key)];
      if (typeof value === 'object' && value) {
        if ('key' in value && String(value.key).startsWith('img-')) {
          return Number(key);
        }
      }

      if (Array.isArray(value)) {
        const hasImage = value.find((val) => {
          if (typeof val === 'object' && val) {
            if ('key' in val && String(val.key).startsWith('img-')) {
              return true;
            }
          }

          return false;
        });

        if (hasImage) {
          return Number(key);
        }
      }

      return undefined;
    });
  }))).filter((index) => index !== undefined);

  const cols: GridColDef[] = dataHeaders.map((header, index) => {
    const columnType = determineColumnType(rows, String(header.id));
    const isLastCell = !(index < dataHeaders.length - 1);
    const calcColumnWidth = width / dataHeaders.length;
    const columnSizeDetermined = determineColumnSize(
      header.name,
      rows,
      String(header.id),
      '18px Roboto',
    );

    const unWrappedColumnWidth = columnSizeDetermined > calcColumnWidth
      ? columnSizeDetermined
      : calcColumnWidth;

    const columnWidth = !isWrapText || nonTextIndexes.includes(index)
      ? unWrappedColumnWidth
      : calcColumnWidth;

    const finalColumnWidth = columnWidth + (index === 0 && ENABLE_ROW_CHECKBOX ? 30 : 0);

    return ({
      field: String(header.id),
      headerName: header.name,
      type: columnType,
      width: finalColumnWidth,
      flex: isLastCell ? 1 : 0,
      minWidth: 120,
      renderHeader: () => (
        (index === 0 && ENABLE_ROW_CHECKBOX) ? (
          <Box component="span" display="flex" alignItems="center" overflow="hidden">
            <Box component="span" flex={0}>
              <Checkbox size="small" className={classes.dataGridCheckbox} />
            </Box>
            <Box component="span" flex={1} overflow="hidden" textOverflow="ellipsis">
              {header.nodes}
            </Box>
          </Box>
        ) : (
          <Box component="span" flex={1} overflow="hidden" textOverflow="ellipsis">
            {header.nodes}
          </Box>
        )
      ),
      renderCell: (params) => {
        const { value } = params;

        if (typeof value !== 'object') {
          return (index === 0 && ENABLE_ROW_CHECKBOX)
            ? (
              <Box component="span" display="flex" alignItems="center" overflow="hidden">
                <Box component="span" flex={0}>
                  <Checkbox size="small" className={classes.dataGridCheckbox} />
                </Box>
                <Box component="span" flex={1} overflow="hidden" textOverflow="ellipsis">
                  {params.value ? String(params.value) : ''}
                </Box>
              </Box>
            ) : (
              <>
                {params.value ? String(params.value) : ''}
              </>
            );
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return (index === 0 && ENABLE_ROW_CHECKBOX)
          ? (
            <Box component="span" display="flex" alignItems="center" overflow="hidden">
              <Box component="span" flex={0}>
                <Checkbox size="small" className={classes.dataGridCheckbox} />
              </Box>
              <Box component="span" flex={1} overflow="hidden" textOverflow="ellipsis">
                {value}
              </Box>
            </Box>
          ) : value;
      },
    });
  });

  function CustomToolbar() {
    const commonCss = useCommonClasses();
    return (
      <GridToolbarContainer
        className={classes.dataGridToolbar}
      >
        <GridToolbarFilterButton
          arrow
          componentsProps={{
            button: {
              variant: 'outlined',
              classes: commonCss.buttons.roundButton,
              className: classNames(
                classes.dataGridToolButton,
                'tertiary',
              ),
            },
          }}
        />
        <Tooltip arrow title="Export">
          <GridToolbarExport
            csvOptions={{
              fileName: exportFileName,
              delimiter: ';',
              utf8WithBom: true,
            }}
          />
        </Tooltip>
        <Tooltip arrow title={!isWrapText ? 'Wrap Columns' : 'Unwrap Columns'}>
          <Button
            variant="outlined"
            classes={commonCss.buttons.roundButton}
            size="small"
            className={
              classNames(
                classes.toolButton,
                'tertiary',
              )
            }
            style={{ paddingLeft: 4, paddingRight: 4 }}
            onClick={() => setWrapText(!isWrapText)}
          >
            <WrapTextIcon />
          </Button>
        </Tooltip>
        {(customTools || []).map((customTool) => (
          <Tooltip key={customTool.key} arrow title={customTool.tooltip}>
            <Button
              variant="outlined"
              classes={commonCss.buttons.roundButton}
              size="small"
              className={
                classNames(
                  classes.toolButton,
                  'tertiary',
                )
              }
              style={{ paddingLeft: 4, paddingRight: 4 }}
              onClick={customTool?.onClick}
            >
              {customTool?.icon}
            </Button>
          </Tooltip>
        ))}
      </GridToolbarContainer>
    );
  }

  return (
    <ThemeProvider theme={dataGridTheme}>
      <div className={classes.root}>
        <DataGrid
          classes={{
            root: classes.dataGridContainer,
            virtualScroller: classes.dataGridScroller,
          }}
          density="compact"
          rows={rows}
          columns={cols}
          getRowHeight={() => 'auto'}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            pagination: {
              rowsPerPageOptions: [10, 25, 50],
            },
            toolbar: {
              csvOptions: { fileName: exportFileName },
              printOptions: { fileName: exportFileName },
            },
          }}
          hideFooter={rows.length <= 10}
          style={{
            marginTop: theme.spacing(1),
          }}
          localeText={{
            toolbarFilters: '',
            toolbarExport: '',
          }}
        />
      </div>
    </ThemeProvider>
  );
};

export default DataTable;
