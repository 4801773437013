import { jsonrepair } from 'jsonrepair';
import refineTableInAnswer from '../../app/features/gpt/gptUtils';
import { SourceInfo } from '../../app/features/gpt/types';

export function stripHttp(value: string) {
  if (value.startsWith('http://')) {
    return value.substring('http://'.length);
  }

  if (value.startsWith('https://')) {
    return value.substring('https://'.length);
  }

  return value;
}

export function stripParams(url: string) {
  return url.indexOf('?') >= 0
    ? url.substring(0, url.indexOf('?'))
    : url;
}

export function splitCamelCases(value: string) {
  return value.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function isAllNumeric(value: string) {
  return /^[0-9]*$/.test(value);
}

export function isStartsWithAlphabet(value: string) {
  return /^[a-zA-Z\\s]/.test(value);
}

export function isBase64(value: string) {
  return value.startsWith('data:');
}

export function isBase64Content(input: string) {
  // Base64 strings can contain only the following characters:
  // A-Z, a-z, 0-9, +, /, and =
  const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

  // Check if the input string matches the base64 regex pattern
  return base64Regex.test(input);
}

export function getCommonWords(aWords: string[], bWords: string[]) {
  const common = [] as string[];

  aWords.forEach((aWord) => {
    if (bWords.includes(aWord)) {
      common.push(aWord);
    }
  });

  return common;
}

export function getGlossaryLinkMarkdown(text: string, highlightedWords: string[]) {
  if (highlightedWords.length === 0 || !text) {
    return text;
  }

  return text.replace(
    new RegExp(String.raw`\[[^\][]*]\([^()]*\)|\b(${highlightedWords.join('|')})\b`, 'gi'),
    (m, g: string, idx: number) => {
      const shouldReplace = text.at(idx - 1) !== '{';
      return (g && shouldReplace ? `[${g}](/glossary#${encodeURIComponent(g)})` : m);
    },
  );
}

export function correctSourceBrackets(input: string): string {
  const regex = /\[([\d, ]+)\]/g;

  return input.replace(regex, (_, numString: string) => {
    const numbers = numString.split(',');

    const convertedNumbers = numbers.map((num) => `[${num.trim()}]`).join('');
    return convertedNumbers;
  });
}

export function getHighlightedSourceMarkdown(
  text: string,
  sources?: SourceInfo[],
  isProcessing?: boolean,
) {
  if (!text) {
    return text;
  }

  const lines = text.split('\n');
  let isInCodeBlock = false;
  return lines.reduce((final, line) => {
    if (line.startsWith('```')) {
      isInCodeBlock = !isInCodeBlock;
    }

    if (isInCodeBlock) {
      return `${final}${line}\n`;
    }

    const processed = correctSourceBrackets(line).replace(
      /\s*\[[0-9]+\]/g,
      (m: string) => {
        const sourceIndex = Number(m.replace(/\[|\]/g, ''));
        const hasSourceFounded = (
          sources?.find((source) => source.metadata.index === sourceIndex)
          || sources?.find((_, index) => (index + 1) === sourceIndex)
          || sourceIndex === 0
        );

        if (hasSourceFounded || isProcessing) {
          return ` [${sourceIndex}](/sources#${sourceIndex})`;
        }

        return '';
      },
    );

    return `${final}${processed}\n`;
  }, '');
}

export function getInlineChartMarkdown(text: string) {
  if (!text) {
    return text;
  }

  let chartIndex = 0;

  return text.replace(
    // /^(\|[^\n]+\|\r?\n)((?:\|:?[-]+:?)+\|)(\n(?:\|[^\n]+\|\r?\n?)*)?$/g,
    /((\r?\n){2}|^)([^\r\n]*\|[^\r\n]*(\r?\n)?)+(?=(\r?\n){2}|$)/gm,
    (m: string) => {
      if (!m.trim().startsWith('|')) {
        return m;
      }

      const link = `${m}\r\n\r\n[inlineChart${chartIndex}](/inlineChart#${chartIndex})\r\n`;
      chartIndex += 1;

      return link;
    },
  );
}

export function isValidUrl(str: string) {
  const pattern = [
    '^(https?:\\/\\/)?', // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|', // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))', // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*', // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?', // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  ].join('');
  const regexp = new RegExp(pattern, 'i');
  return !!regexp.test(str);
}

export function extractTableMarkdown(input: string): string[] {
  const refinedInput = refineTableInAnswer(input);

  // Regular expression to match table rows
  const tableRowRegex = /\|([^|]*)\|/g;

  // Regular expression to match table header separator
  const headerSeparatorRegex = /^\|?(-+\|?)+$/;

  const result: string[] = [];
  let currentTable: string[] = [];
  let i = 0;

  // Split the input string into lines
  const lines = refinedInput.split('\n');

  while (i < lines.length) {
    const trimmedLine = lines[i].trim();

    // Check if the current line is a table header separator
    if (headerSeparatorRegex.test(trimmedLine)) {
      currentTable.push(trimmedLine);
      i += 1;

      // eslint-disable-next-line no-continue
      continue;
    }

    // Match table cells in the current line
    const matches = trimmedLine.match(tableRowRegex);

    if (matches) {
      currentTable.push(trimmedLine);
    } else if (currentTable.length > 0) {
      // If a non-table line is encountered after a table row, store the current table and reset
      result.push(currentTable.join('\n'));
      currentTable = [];
    }

    i += 1;
  }

  // Push the last table if it exists
  if (currentTable.length > 0) {
    result.push(currentTable.join('\n'));
  }

  return result;
}

export function extractJsonStrings(input: string): string[] {
  // Regular expression to match JSON-like strings
  const jsonRegex = /{(?:[^{}]|(?:\\\{|\\\\}))*}/g;

  // Use match() with the regular expression to find all JSON-like strings
  const matches = input.match(jsonRegex) || [];

  return matches;
}

export function isJsonString(input: string) {
  try {
    JSON.parse(input);
    return true;
  } catch {
    return false;
  }
}

export function isJsonArray(input: string) {
  try {
    const s = JSON.parse(input);
    return Array.isArray(s);
  } catch {
    return false;
  }
}

export function isRepairableJson(input: string) {
  const trimmed = input.trim();
  if (!trimmed.startsWith('[') && !trimmed.startsWith('{')) {
    return false;
  }

  try {
    jsonrepair(input);
    return true;
  } catch {
    return false;
  }
}

export function extractJsonCodePart(input: string) {
  const refinedInput = input.replace(/}{/g, '}\n{');

  const lines = refinedInput.split('\n');
  const codeLines: string[] = [];
  let inCodeBlock = false;

  lines.forEach((line) => {
    if (isJsonString(line)) {
      codeLines.push(line);
      codeLines.push('<br>');
      return;
    }

    if (line.startsWith('{') || line.startsWith('[')) {
      inCodeBlock = true;
    }

    if (inCodeBlock) {
      codeLines.push(line);
    }

    if (line.startsWith('}') || line.startsWith(']')) {
      inCodeBlock = false;
      codeLines.push(line);
      codeLines.push('<br>');
    }
  });

  return codeLines.join('\n');
}

export function stripJsonCodePart(
  input: string,
  options?: {
    ignoreNonJsonBefore: boolean,
  },
) {
  const refinedInput = input.replace(/}{/g, '}\n{');

  const lines = refinedInput.split('\n').filter((line) => !/^```$/.test(line));
  const codeLines: string[] = [];
  let inCodeBlock = false;

  if (options?.ignoreNonJsonBefore) {
    const startCodeIndex = lines.findIndex((line) => (
      line.startsWith('{') || line.startsWith('[')
    ));

    if (startCodeIndex >= 0) {
      lines.splice(0, startCodeIndex);
    }
  }

  lines.forEach((line) => {
    if (isJsonString(line)) {
      return;
    }

    if (line.startsWith('{') || line.startsWith('[')) {
      inCodeBlock = true;
      return;
    }

    if (!inCodeBlock) {
      codeLines.push(line);
    }

    if (
      line.startsWith('}')
      || line.startsWith(']')
    ) {
      inCodeBlock = false;
    }
  });

  return codeLines.join('\n');
}

export function isTableMarkdown(input?: string): boolean {
  if (!input) {
    return false;
  }

  const lines = input.split('\n')
    .map((line) => line.trim())
    .filter((line) => !!line);

  if (lines.length < 4) {
    return false;
  }

  if (lines.some((line) => !line.startsWith('|'))) {
    return false;
  }

  return true;
}
