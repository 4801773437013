import NetworkState from '../../../core/types/network';
import { QuickChartInfo, QuickChartUpdateInfo, QuickChartUpdateResponse } from '../../../core/types/quickChart';
import { RelationData } from '../relationChart/types';
import { CompanyItem } from '../search/types';

export enum GptActions {
  CHAT_RESPONSE_REQUEST = '@@gpt/CHAT_RESPONSE_REQUEST',
  CHAT_RESPONSE_SET = '@@gpt/CHAT_RESPONSE_SET',
  CHAT_RESPONSE_MODEL_SELECT = '@@gpt/CHAT_RESPONSE_MODEL_SELECT',
  CHAT_REQUEST_CANCEL = '@@gpt/CHAT_REQUEST_CANCEL',
  CHAT_CLEAN_REQUEST = '@@gpt/CHAT_CLEAN_REQUEST',
  CHAT_CLEAN_SET = '@@gpt/CHAT_CLEAN_SET',
  CHAT_ITEM_STEP_TOGGLE = '@@gpt/CHAT_ITEM_STEP_TOGGLE',
  CHAT_ITEM_TABLE_TOGGLE = '@@gpt/CHAT_ITEM_TABLE_TOGGLE',
  CHAT_ITEM_SOURCE_TOGGLE = '@@gpt/CHAT_ITEM_SOURCE_TOGGLE',
  CHAT_ITEM_ORGS_TOGGLE = '@@gpt/CHAT_ITEM_ORGS_TOGGLE',
  CHAT_ITEM_FAQS_TOGGLE = '@@gpt/CHAT_ITEM_FAQS_TOGGLE',
  CHAT_ITEM_CHART_DUPLICATE = '@@gpt/CHAT_ITEM_CHART_DUPLICATE',
  CHAT_ITEM_CHART_REMOVE = '@@gpt/CHAT_ITEM_CHART_REMOVE',
  CHAT_ITEM_CHART_UPDATE_REQUEST = '@@gpt/CHAT_ITEM_CHART_UPDATE_REQUEST',
  CHAT_ITEM_CHART_UPDATED_SET = '@@gpt/CHAT_ITEM_CHART_UPDATED_SET',
  CHAT_ITEM_CHART_CHANGE_UNDO = '@@gpt/CHAT_ITEM_CHART_CHANGE_UNDO',
  CHAT_STRAMING_SET = '@@gpt/CHAT_STREAMING_SET',
  CHAT_HISTORY_REQUEST = '@@gpt/CHAT_HISTORY_REQUEST',
  CHAT_HISTORY_SET = '@@gpt/CHAT_HISTORY_SET',
  CHAT_HISTORY_APPLY = '@@gpt/CHAT_HISTORY_APPLY',
  CHAT_HISTORY_DELETE_REQUEST = '@@gpt/CHAT_HISTORY_DELETE_REQUEST',
  CHAT_HISTORY_DELETED_SET = '@@gpt/CHAT_HISTORY_DELETED_SET',
  CHAT_PROMPTS_REQUEST = '@@gpt/CHAT_PROMPTS_REQUEST',
  CHAT_PROMPTS_SET = '@@gpt/CHAT_PROMPTS_SET',
  CHAT_PROMPT_CREATE_REQUEST = '@@gpt/CHAT_PROMPT_CREATE_REQUEST',
  CHAT_PROMPT_CREATED_SET = '@@gpt/CHAT_PROMPT_CREATED_SET',
  CHAT_PROMPT_UPDATE_REQUEST = '@@gpt/CHAT_PROMPT_UPDATE_REQUEST',
  CHAT_PROMPT_UPDATED_SET = '@@gpt/CHAT_PROMPT_UPDATED_SET',
  CHAT_PROMPT_DELETE_REQUEST = '@@gpt/CHAT_PROMPT_DELETE_REQUEST',
  CHAT_PROMPT_DELETED_SET = '@@gpt/CHAT_PROMPT_DELETED_SET',
  CHAT_OUTPUTS_COMPARE_APPLY = '@@gpt/CHAT_OUTPUTS_COMPARE_APPLY',
  GPT_MODELS_REQUEST = '@@gpt/GPT_MODELS_REQUEST',
  GPT_MODELS_SET = '@@gpt/GPT_MODELS_SET',
  SITE_EXAMPLES_REQUEST = '@@gpt/SITE_EXAMPLES_REQUEST',
  SITE_EXAMPLES_SET = '@@gpt/SITE_EXAMPLES_SET',
  SOURCE_BUBBLE_OPEN = '@@gpt/SOURCE_BUBBLE_OPEN',
  SOURCE_BUBBLE_CLOSE = '@@gpt/SOURCE_BUBBLE_CLOSE',
  ENTITY_BUBBLE_OPEN = '@@gpt/ENTITY_BUBBLE_OPEN',
  ENTITY_BUBBLE_CLOSE = '@@gpt/ENTITY_BUBBLE_CLOSE',
  CHAT_ITEM_TASKS_SET = '@@gpt/CHAT_ITEM_TASKS_SET',
  TASKS_EXECUTE_START = '@@gpt/TASKS_EXECUTE_START',
  TASKS_EXECUTED_SET = '@@gpt/TASKS_EXECUTED_SET',
  DEEP_SEARCH_TASKS_PREPARE = '@@gpt/DEEP_SEARCH_TASKS_PREPARE',
  TASKER_EXECUTE_REQUEST = '@@gpt/TASKER_EXECUTE_REQUEST',
  TASKER_EXECUTED_SET = '@@gpt/TASKER_EXECUTED_SET',
  LAST_OBJECTIVE_UPDATE = '@@gpt/LAST_OBJECTIVE_UPDATE',
  TASK_TEMPLATES_REQUEST = '@@gpt/TASK_TEMPLATES_REQUEST',
  TASK_TEMPLATES_SET = '@@gpt/TASK_TEMPLATES_SET',
  TASK_TEMPLATE_APPLY = '@@gpt/TASK_TEMPLATE_APPLY',
  TASK_TEMPLATE_CREATE_REQUEST = '@@gpt/TASK_TEMPLATE_CREATE_REQUEST',
  TASK_TEMPLATE_CREATED_SET = '@@gpt/TASK_TEMPLATE_CREATED_SET',
  TASK_TEMPLATE_UPDATE_REQUEST = '@@gpt/TASK_TEMPLATE_UPDATE_REQUEST',
  TASK_TEMPLATE_UPDATED_SET = '@@gpt/TASK_TEMPLATE_UPDATED_SET',
  TASK_TEMPLATE_DELETE_REQUEST = '@@gpt/TASK_TEMPLATE_DELETE_REQUEST',
  TASK_TEMPLATE_DELETED_SET = '@@gpt/TASK_TEMPLATE_DELETED_SET',
  TABLE_TO_CHART_REQUEST = '@@gpt/TABLE_TO_CHART_REQUEST',
  TABLE_TO_CHART_SET = '@@gpt/TABLE_TO_CHART_SET',
  FOLLOW_UP_QUESTIONS_REQUEST = '@@gpt/FOLLOW_UP_QUESTIONS_REQUEST',
  FOLLOW_UP_QUESTIONS_SET = '@@gpt/FOLLOW_UP_QUESTIONS_SET',
  SETTINGS_UPDATE = '@@gpt/SETTINGS_UPDATE',
  SIDE_BAR_OPEN_SET = '@@gpt/SIDE_BAR_OPEN_SET',
  SELECTED_SIDE_BAR_TAB_SET = '@@gpt/SELECTED_SIDE_BAR_TAB_SET',
  PLAN_EXEC_TASK_UPDATE = '@@gpt/PLAN_EXEC_TASK_UPDATE',
  PLAN_EXEC_UPDATE = '@@gpt/PLAN_EXEC_UPDATE',
  PLAN_EXEC_RUN_REQUEST = '@@gpt/PLAN_EXEC_RUN_REQUEST',
  PLAN_EXEC_RUN_SET = '@@gpt/PLAN_EXEC_RUN_SET',
  SHOWING_TOOLS_AT_HOME_SET = '@@gpt/SHOWING_TOOLS_AT_HOME_SET',
  SHOWING_GUIDES_AT_HOME_SET = '@@gpt/SHOWING_GUIDES_AT_HOME_SET',
  SOURCE_CITATION_TOGGLE = '@@gpt/SOURCE_CITATION_TOGGLE',
  JINA_CONTENT_REQUEST = '@@gpt/JINA_CONTENT_REQUEST',
  JINA_CONTENT_SET = '@@gpt/JINA_CONTENT_SET',
  LOCAL_SOURCE_REQUEST = '@@gpt/LOCAL_SOURCE_REQUEST',
  LOCAL_SOURCE_SET = '@@gpt/LOCAL_SOURCE_SET',
}

export type ChatStates = 'ends' | 'requesting' | 'streaming' | 'reset' | 'editing' | 'followUp';

export type IntermediateStep = [
  string[],
  string | SourceInfo[] | StepOutputInfo,
];

export interface AgentInfo {
  name: string;
  value: string;
  description: string;
}

export interface IntermediateStepHeader {
  task?: string;
  log?: string;
  tool: string;
  type?: string;
  toolInput?: string;
  toolOutput?: string;
}

export interface ChatContentItem {
  content: string;
  additionalKwargs: string;
}

export interface StepOutputInfo {
  query: string;
  result: string;
  sources?: ApiSourceInfo[];
}

export interface SourceInfo {
  index?: number;
  sourceId?: string;
  sourcePageNo?: number;
  metadata: SourceMetadata;
  pageContent?: string;
  table?: string;
  domain?: string;
  favIconUrl?: string;
}

export interface ApiSourceInfo {
  index?: number;
  source_id?: string;
  page_no?: number;
  title: string;
  href: string;
  body?: string;
  table?: string;
  question?: string;
  answer_type?: string;
}

export interface SourceMetadata {
  index?: number;
  title?: string;
  source?: string;
  question?: string;
  answerType?: string;
}

export type ChatResponseMode = 'task' | 'customTask' | 'summary';

export type ChartResponse = {
  [key: `inlineGraph${number}`]: QuickChartInfo;
  [key: `attachedGraph${number}`]: QuickChartInfo;
};

export type ChatResponse = {
  input: string;
  chatHistory: ChatContentItem[];
  output: string;
  intermediateSteps: IntermediateStep[];
  mode?: ChatResponseMode;
  orgs?: CompanyItem[];
  exception?: string;
  needPlan?: boolean;
  isExecutePlan?: boolean;
} & ChartResponse;

export interface ChatHistoryItem {
  id: string;
  latestChatItemId: number;
  netGraph?: RelationData;
  subItems: ChatHistorySubItem[];
  updated?: Date;
}

export interface ChatHistoryResponse {
  count: number;
  next?: string;
  previous?: string;
  results: ChatHistorySubItem[];
}

export interface ChatHistorySubItem {
  id: number;
  agent?: string;
  compareModels?: boolean;
  provider: number;
  conversationId: string;
  providerSpecificId?: string;
  inputText: string;
  llmModel?: string;
  outputText: string;
  user: number;
  netGraph?: RelationData;
  deleted: boolean;
  needPlan?: boolean;
  updated?: Date;
}

export interface ChatPromptsResponse {
  count: number;
  results: ChatPromptItem[];
}

export interface ChatPromptItem {
  id?: number;
  prompt: string;
  user: number;
}

export interface ChatStepItemInfo {
  name: string;
  value: string;
}

export interface ChatStepInfo {
  items: ChatStepItemInfo[];
  attachments?: Record<string, string>[];
  isPlanExecuteTask?: boolean;
}

export interface ChatFileInfo {
  fileName: string;
  href: string;
  fileType: string;
  data?: {
    headers: string[];
    items: unknown[];
  };
  source?: string;
  isSourceMetaTable?: boolean;
}

export interface StructuredContentInfo {
  preUnstructured?: string;
  structured: unknown;
}

export interface PlanAndExecuteInfo {
  tasks: PlanAndExecuteTask[];
}

export interface PlanAndExecuteTask {
  step_hierarchy: number;
  task: string;
  tool: string;
  tool_input: string;
  tool_output?: Record<string, unknown>;
  originalTaskIndex: number;
  isCustomTask?: boolean;
}

export interface PlanTaskGroup {
  step: number;
  tasks: PlanAndExecuteTask[];
}

export interface ChatResponseException {
  msg: string;
  details?: string;
}

export interface FollowUpInfo {
  questions: FollowUpQuestionItem[];
}

export interface FollowUpQuestionItem {
  question: string;
}

export interface GptChatItem {
  type: 'user' | 'bot';
  steps?: ChatStepInfo[];
  files?: ChatFileInfo[];
  content?: string;
  structuredContent?: StructuredContentInfo;
  evaluation?: string;
  summary?: string;
  sources?: SourceInfo[];
  orgs?: CompanyItem[];
  objectives?: string[];
  isShowingSteps?: boolean;
  isShowingTables?: boolean;
  isShowingSources?: boolean;
  isShowingFaqs?: boolean;
  isShowingOrgs?: boolean;
  isProcessing?: boolean;
  error?: Error;
  mode?: ChatResponseMode;
  tasks?: GptTaskItem[];
  inlineCharts?: (QuickChartInfo | undefined)[][];
  attachedCharts?: (QuickChartInfo | undefined)[][];
  llmModel?: string;
  agent?: string;
  needPlan?: boolean;
  exception?: string | ChatResponseException;
  executingTasks?: PlanAndExecuteTask[];
  finishedTasks?: PlanAndExecuteTask[];
  followUp?: FollowUpInfo;
}

export interface GptChatModelSelection {
  selectedModel: string;
  chatByEngines: GptChatByEngine[];
}

export interface GptChatByEngine {
  llm: string;
  agent?: string;
  isStreaming: boolean;
  chat?: GptChatItem;
}

export interface GptTaskItem {
  id: string;
  value?: string;
}

export interface SiteExampleItem {
  id: string;
  category: string;
  sitetoolName: string;
  sitetoolDesc: string;
  example: string;
  icon?: string;
  active: boolean;
}

export interface SiteExampleDataSet {
  dataSetName: string;
  description: string;
  examples: string[];
  icon?: string;
  active: boolean;
}

export interface SiteExampleGroup {
  groupName: string;
  dataSets: SiteExampleDataSet[];
  layout?: number;
}

export interface SiteExampleResponse {
  results: SiteExampleItem[];
}

export interface GptTaskerInputItem {
  task: string;
  answer: string;
  sources?: SourceInfo[];
}

export interface GetTaskerSummaryResult {
  answersSources: ApiSourceInfo[];
  evaluation: string;
  finalSummary: string;
  newTasks?: string[];
  objective: string;
  summarySources: ApiSourceInfo[];
  taskAnswers: string;
}

export interface TaskTemplate {
  id?: string;
  tag: string;
  objective: string;
  tasks: string[];
}

export interface GptModelResponse {
  count: number;
  next?: string;
  previous?: string;
  results?: GptModel[];
}

export interface GptModel {
  provider: string;
  owner: string;
  permLevel: number;
  permLevelDisplay: string;
  modelName: string;
  suffixName?: string;
  modelInput: string;
  contextK: number;
  parametersB: number;
  inputCost: number;
  outputCost: number;
  tokenPerSec: number;
  displayOrder: number;
  default: boolean;
  active: boolean;
}

export interface GptSettings {
  gptModelInput?: string;
  gptModelName?: string;
  temperature: number;
  agent?: string;
  showPlan?: boolean;
}

export interface GptTool {
  name: string;
  description: string;
  icon?: string;
  sortOrder?: number;
}

export interface LocalSourceInfo {
  title: string;
  sourceId: string;
  pdf?: string;
  markdown?: string;
  pageNumber?: number;
}

export interface RequestChatResponseArgs {
  input: string;
  mode?: ChatResponseMode;
  llm?: string;
  isCompare?: boolean;
  isExecutePlan?: boolean;
  isTest?: boolean;
}

export interface ApplyChatOutputsCompareArgs {
  input: string;
  llmModel: string;
}

export interface SetChatResponseArgs {
  llmModel?: string;
  response?: ChatResponse;
  error?: Error;
}

export interface SetChatCleanArgs {
  error?: Error;
}

export interface ToggleChatItemStepArgs {
  chatItemIndex: number;
  isShowing: boolean;
}

export interface ToggleChatItemTableArgs {
  chatItemIndex: number;
  isShowing: boolean;
}

export interface ToggleChatItemSourceArgs {
  chatItemIndex: number;
  isShowing: boolean;
}

export interface ToggleChatItemFaqsArgs {
  chatItemIndex: number;
  isShowing: boolean;
}

export interface ToggleChatItemOrgsArgs {
  chatItemIndex: number;
  isShowing: boolean;
}

export interface SetChatStreamingArgs {
  data?: string;
  chatSessionId?: string;
  agent?: string;
  exception?: string;
  needPlan?: boolean;
  executingTasks?: PlanAndExecuteTask[];
  finishedTasks?: PlanAndExecuteTask[];
}

export interface SetChatHistoryArgs {
  items?: ChatHistorySubItem[];
  error?: Error;
}

export interface RequestChatPromptsArgs {
  user: number;
}

export interface SetChatPromptsArgs {
  promptResponse?: ChatPromptsResponse;
  error?: Error;
}

export interface RequestCreateChatPromptArgs {
  prompt: ChatPromptItem;
}

export interface SetChatPromptCreatedArgs {
  prompt?: ChatPromptItem;
  error?: Error;
}

export interface RequestUpdateChatPromptArgs {
  prompt: ChatPromptItem;
}

export interface SetChatPromptUpdatedArgs {
  prompt?: ChatPromptItem;
  error?: Error;
}

export interface RequestDeleteChatPromptArgs {
  prompt: ChatPromptItem;
}

export interface SetChatPromptDeletedArgs {
  prompt?: ChatPromptItem;
  error?: Error;
}

export interface SetSiteExamplesArgs {
  siteExamplesResponse?: SiteExampleResponse;
  error?: Error;
}

export interface ApplyChatHistoryArgs {
  id: string;
  items: ChatHistorySubItem[];
  netGraph?: RelationData;
}

export interface RequestDeleteChatHistoryArgs {
  id: number;
  conversationId: string;
}

export interface SetChatHistoryDeletedArgs {
  id: number;
  conversationId: string;
  error?: Error;
}

export interface OpenSourceBubbleArgs {
  id: string;
  sourceIndex: number;
  sourceInfo?: SourceInfo;
  originX: number;
  originY: number;
}

export interface StartTasksExecuteArgs {
  mode?: 'deepSearch';
  objective?: string;
  tasks: GptTaskItem[];
}

export interface SetChatItemTasksArgs {
  chatIndex: number;
  tasks: GptTaskItem[];
}

export interface PrepareDeepSearchTasksArgs {
  objectives?: string[];
  tasks: GptTaskItem[];
}

export interface RequestTaskerExecuteArgs {
  objective?: string;
  tasks: GptTaskerInputItem[];
}

export interface SetTaskerExecutedArgs {
  result?: GetTaskerSummaryResult;
  error?: Error;
}

export interface UpdateLastObjectiveArgs {
  index: number;
  objective: string;
}

export interface SetTaskTemplatesArgs {
  templates?: TaskTemplate[];
  error?: Error;
}

export interface ApplyTaskTemplateArgs {
  chatIndex: number;
  template: TaskTemplate;
}

export interface RequestCreateTaskTemplateArgs {
  template: TaskTemplate;
}

export interface SetTaskTemplateCreatedArgs {
  template?: TaskTemplate;
  error?: Error;
}

export interface RequestUpdateTaskTemplateArgs {
  template: TaskTemplate;
}

export interface SetTaskTemplateUpdatedArgs {
  template?: ChatPromptItem;
  error?: Error;
}

export interface RequestDeleteTaskTemplateArgs {
  template: TaskTemplate;
}

export interface SetTaskTemplateDeletedArgs {
  templates?: TaskTemplate[];
  error?: Error;
}

export interface DuplicateChatItemChartArgs {
  chartType: 'inline' | 'attached';
  chatItemIndex: number;
  chartIndex: number;
  chartSubIndex: number;
  chartInfo?: QuickChartInfo;
}

export interface RemoveChatItemChartArgs {
  chartType: 'inline' | 'attached';
  chatItemIndex: number;
  chartIndex: number;
  chartSubIndex: number;
}

export interface RequestChatItemChartUpdateArgs {
  chartType: 'inline' | 'attached';
  chatItemIndex: number;
  chartIndex: number;
  chartSubIndex: number;
  chartModifyInfo: QuickChartUpdateInfo;
}

export interface SetChatItemChartUpdatedArgs {
  chartType: 'inline' | 'attached';
  chatItemIndex: number;
  chartIndex: number;
  chartSubIndex: number;
  chartModifyInfo?: QuickChartUpdateInfo,
  chartUpdateResult?: QuickChartUpdateResponse;
  error?: Error;
}

export interface UndoChatItemChartChangeArgs {
  chartType: 'inline' | 'attached';
  chatItemIndex: number;
  chartIndex: number;
  chartSubIndex: number;
}

export interface RequestTableToChartArgs {
  tableMarkdown: string;
  chatItemIndex: number;
  chartIndex: number;
}

export interface SetTableToChartArgs {
  chatItemIndex: number;
  chartIndex: number;
  chartItems?: ChartResponse[];
  error?: Error;
}

export interface SetGptModelsArgs {
  permLevel: number;
  response?: GptModelResponse;
  error?: Error;
}

export interface UpdateSettingsArgs {
  settings: GptSettings;
}

export interface SetSideBarOpenArgs {
  isOpen: boolean;
}

export interface SetSelectedSideBarTabArgs {
  tabName: string;
}

export interface SelectChatResponseModelArgs {
  chatItemIndex: number;
  llm: string;
}

export interface UpdatePlanExecArgs {
  chatItemIndex: number;
  planAndExec: PlanAndExecuteInfo;
}

export interface UpdatePlanExecTaskArgs {
  chatItemIndex: number;
  taskIndex: number;
  tool: string;
  task: string;
  toolInput: string;
}

export interface SetShowingToolsAtHomeArgs {
  isShowing: boolean;
}

export interface SetShowingGuidesAtHomeArgs {
  isShowing: boolean;
}

export interface RequestFollowUpQuestionsArgs {
  sessionId: string;
  isStreaming?: boolean;
}

export interface SetFollowUpQuestionsArgs {
  isStreaming?: boolean;
  result?: FollowUpInfo;
  error?: Error;
}

export interface ToggleSourceCitationArgs {
  isOpen: boolean;
  url?: string;
  phrase?: string;
  title?: string;
  sourceId?: string;
  pageNumber?: number;
  timestamp?: Date;
}

export interface RequestJinaContentArgs {
  url: string;
  format?: string;
}

export interface SetJinaContentArgs {
  url: string;
  result?: string;
  error?: Error;
  format?: string;
}

export interface RequestLocalSourceArgs {
  sourceId: string;
  title?: string;
  pageNumber?: number;
}

export interface SetLocalSourceArgs {
  title?: string;
  result?: LocalSourceInfo;
  error?: Error;
}

export interface GptState {
  networkStates: {
    chatResponseRequest: NetworkState;
    chatCleanRequest: NetworkState;
    chatHistoryRequest: NetworkState;
    chatHistoryDeleteRequest: NetworkState;
    chatPromptsRequest: NetworkState;
    chatPromptCreateRequest: NetworkState;
    chatPromptUpdateRequest: NetworkState;
    chatPromptDeleteRequest: NetworkState;
    siteExamplesRequest: NetworkState;
    taskerExecuteRequest: NetworkState;
    taskTemplatesRequest: NetworkState;
    chatItemChartUpdateRequest: NetworkState;
    tableToChartRequest: NetworkState;
    gptModelsRequest: NetworkState;
    followUpQuestionsRequest: NetworkState;
    jinaContentRequest: NetworkState;
    localSourceRequest: NetworkState;
  };
  chatId?: string;
  chatSessionId?: string;
  chats: GptChatItem[];
  chatStreaming?: string;
  llmChatItemsByIndexes?: Record<number, GptChatModelSelection>;
  chatHistoryItems?: ChatHistoryItem[];
  chatPromptItems?: ChatPromptItem[];
  siteExampleItems?: SiteExampleItem[];
  siteExampleGroups?: SiteExampleGroup[];
  availableTools?: GptTool[];
  homePageExamples?: SiteExampleItem[];
  chatState?: ChatStates;
  sourceBubble?: {
    id: string;
    isOpen: boolean;
    sourceIndex: number;
    sourceInfo?: SourceInfo;
    originX: number;
    originY: number;
  };
  tasksExecution?: {
    isExecuting: boolean;
    tasks: GptTaskItem[];
    currentIndex: number;
    startTime: Date;
  };
  sourceCitation?: {
    isOpen: boolean;
    url?: string;
    phrase?: string;
    sourceId?: string;
    title?: string;
    timestamp?: Date;
  };
  lastTaskObjective?: string;
  lastTaskExecuted?: Date;
  lastTaskExecDurationInSeconds?: number;
  taskTemplates?: TaskTemplate[];
  gptModels?: GptModel[];
  settings: GptSettings,
  isSideBarOpen: boolean;
  isSideBarClosedOnce: boolean;
  selectedSideBarTab?: string;
  isShowingToolsAtHome: boolean;
  isShowingGuidesAtHome: boolean;
  jinaContentCache?: Record<string, Record<string, string>>;
  jinaContent?: string;
  jinaContentFormat?: string;
  localSource?: LocalSourceInfo;
}
