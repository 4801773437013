import { Theme } from '@mui/material';
import { createStyles, StyleRules } from '@mui/styles';
import Color from 'color';

import getCommonStyles, {
  getColorModifierClasses,
  getContrastColorModifierClasses,
  getLightColorModifierClasses,
} from '../../../../theme/commonStyles';
import {
  otherAltLightColorSets,
  otherColorSets,
  otherContrastColorSets,
  sourceRefLightColor,
} from '../../../../theme/palette';
import dimensions from '../../../../theme/dimensions';

const PORTAL_WIDTH = dimensions.gptPortal.width;
const CHAT_WIDTH = PORTAL_WIDTH - 200;

const styles = (theme: Theme): StyleRules<{}, string> => {
  const commonStyles = getCommonStyles(theme);
  return createStyles({
    ...commonStyles,
    root: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    gptChatItem: {
      flex: 1,
      marginLeft: theme.spacing(1),
      padding: `${theme.spacing(0)} ${theme.spacing(7)} ${theme.spacing(0)} ${theme.spacing(1)}`,
      '&.error': {
        fontStyle: 'italic',
        color: theme.palette.error.main,
      },
      fontWeight: '300 !important',
      maxWidth: CHAT_WIDTH,
      '&.task': {
        paddingTop: theme.spacing(2),
      },
      '&.steps': {
        paddingTop: theme.spacing(2),
      },
    },
    gptChatTypeIcon: {
      flex: 0,
      flexBasis: theme.spacing(5),
      height: theme.spacing(5),
      width: theme.spacing(5),
      borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
      borderWidth: 1,
      borderStyle: 'solid',
      ...getColorModifierClasses('borderColor'),
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'sticky',
      top: theme.spacing(2),
      '&.hidden': {
        opacity: 0,
      },
      '& .chatUserIcon': {
        stroke: theme.palette.grey[900],
      },
    },
    gptChatTypeSvg: {
      width: theme.spacing(2.5),
    },
    gptChatItemBotToolsBox: {
      flex: 0,
      flexBasis: 140,
      display: 'flex',
      justifyContent: 'flex-end',
      '& svg': {
        color: theme.palette.grey[50],
      },
      '& button:hover svg': {
        color: 'inherit',
      },
    },
    gptChatItemUserToolsBox: {
      flex: 0,
      flexBasis: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      '& svg': {
        color: theme.palette.grey[50],
      },
      '& button:hover svg': {
        color: 'inherit',
      },
    },
    fileName: {
      fontSize: '1.0714rem',
      fontWeight: 'normal !important',
      marginLeft: `${theme.spacing(0.5)} !important`,
      textDecorationColor: theme.palette.grey[500],
    },
    gptPromptIcon: {
      width: theme.spacing(3),
      alignSelf: 'flex-start',
    },
    gptHideStepsButton: {
      color: `${theme.palette.grey[500]} !important`,
      fontSize: '1.071rem !important',
      fontWeight: 'normal !important',
      textDecoration: 'underline !important',
      textDecorationColor: `${theme.palette.grey[500]} !important`,
    },
    gptCaretUpIcon: {
      width: theme.spacing(2),
      transition: 'all ease-out 0.3s',
      transform: 'rotate(180deg)',
      '&.rotate': {
        transform: 'rotate(90deg)',
      },
      '& #svgCaretUpIcon': {
        stroke: `${otherContrastColorSets[2]} !important`,
      },
    },
    sectionHeader: {
      fontSize: '1.1rem',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 1.7,
      marginRight: theme.spacing(1),
    },
    dataSetIconBox: {
      flex: 0,
      flexBasis: 40,
      paddingTop: 4,
    },
    dataSetContentBox: {
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    dataSetContentHeader: {
      display: 'flex',
    },
    dataSetContentTitle: {
      fontSize: '1.1rem !important',
      fontWeight: 'normal !important',
      color: theme.palette.grey[900],
      lineHeight: 1.15,
      display: 'flex',
      flexDirection: 'column',
      height: 40,
      justifyContent: 'center',
      '&.multi-rows': {
        flexBasis: 150,
        marginRight: 20,
        justifyContent: 'center',
      },
    },
    dataSetContentDesc: {
      display: 'flex',
      flex: 1,
      fontSize: '1rem !important',
      fontWeight: 'normal !important',
      color: theme.palette.grey[500],
      alignItems: 'center',
      marginTop: -1,
    },
    dataSetExamplesBox: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    dataSetExample: {
      display: 'inline-block',
      color: `${theme.palette.grey[900]} !important`,
      textDecorationColor: `${otherContrastColorSets[2]} !important`,
      textDecoration: 'none !important',
      fontSize: '0.9285rem !important',
      fontWeight: '300 !important',
      cursor: 'pointer',
      padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
      borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 0`,
      border: `1px solid ${theme.palette.grey[50]}`,
      '&:hover': {
        ...getLightColorModifierClasses('backgroundColor'),
      },
    },
    siteExIcon: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '12px 12px 12px 0',
      borderWidth: 1,
      borderStyle: 'solid',
      ...getColorModifierClasses('borderColor'),
      '&.primary': {
        ...getContrastColorModifierClasses('borderColor'),
      },
      '&.pvalyou': {
        borderColor: theme.palette.secondary.main,
        width: `${theme.spacing(4)} !important`,
      },
    },
    sourceList: {
      marginTop: 0,
      counterReset: 'list',
      listStylePosition: 'inside',
      paddingInlineStart: '2px',
      '& > li': {
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: theme.typography.body1.fontSize,
        marginBottom: theme.spacing(2),
        '& > a': {
          flex: 1,
          color: `${theme.palette.grey[700]} !important`,
          fontSize: '0.928rem !important',
          fontWeight: 'bold',
          textDecorationColor: `${theme.palette.grey[700]} !important`,
        },
      },
    },
    sourceContentBox: {
      borderLeft: `1px solid ${theme.palette.grey[50]}`,
      marginLeft: theme.spacing(0.25),
      marginTop: theme.spacing(0.5),
      width: '100%',
    },
    sourceFavIcon: {
      width: theme.spacing(2),
      verticalAlign: 'center',
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      filter: 'grayscale(100%)',
    },
    sourceDomain: {
      color: `${theme.palette.grey[300]} !important`,
      fontSize: '0.714rem !important',
      letterSpacing: '0.04px',
      fontWeight: 'normal !important',
      display: 'inline-block',
      verticalAlign: 'center',
    },
    executeBox: {
      marginTop: theme.spacing(2),
      marginLeft: `${theme.spacing(0)} !important`,
    },
    executeButton: {
      width: 136,
      color: `${theme.palette.grey[900]} !important`,
      border: `1px solid ${otherContrastColorSets[1]} !important`,
      backgroundColor: `${sourceRefLightColor} !important`,
      '&:hover, &:focus': {
        color: `${theme.palette.common.white} !important`,
        backgroundColor: `${otherColorSets[2]} !important`,
      },
    },
    executeEstimate: {
      color: `${theme.palette.grey[200]} !important`,
    },
    objective: {
      fontSize: '1.214rem !important',
      fontWeight: '500 !important',
      color: `${theme.palette.grey[900]} !important`,
      marginTop: '3px !important',
      marginBottom: `${theme.spacing(3)} !important`,
      flex: 0,
    },
    expander: {
      display: 'flex',
      marginLeft: theme.spacing(2),
      width: 'calc(100% - 72px)',
    },
    expanderContent: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      // backgroundColor: otherAltLightColorSets[2],
      borderLeft: `1px solid ${theme.palette.grey[50]}`,
      '&.highlight': {
        borderLeft: `1px solid ${otherContrastColorSets[2]}`,
      },
      '& button': {
        color: `${otherContrastColorSets[2]} !important`,
        textTransform: 'uppercase',
        justifyContent: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      '& svg': {
        color: theme.palette.grey[50],
      },
      '& button:hover svg': {
        color: 'inherit',
      },
    },
    otherSectionsDivider: {
      marginLeft: theme.spacing(2),
      width: 'calc(100% - 72px)',
    },
    attachedCharts: {
      marginLeft: theme.spacing(2),
      width: 'calc(100% - 72px)',
    },
    sourcesBox: {
      marginLeft: theme.spacing(2),
      width: 'calc(100% - 72px)',
    },
    faqBox: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      width: '60%',
    },
    chatItemMoreCommandsContainer: {
      marginLeft: theme.spacing(2),
      width: 'calc(100% - 72px)',
    },
    relatedCompanyItem: {
      textAlign: 'center',
      position: 'relative',
      '&:not(:first-child)': {
        marginLeft: theme.spacing(0),
      },
      cursor: 'pointer',
      paddingTop: theme.spacing(1),
      borderRadius: theme.spacing(1),
      '&:hover': {
        backgroundColor: `${otherAltLightColorSets[2]} !important`,
      },
    },
    relatedCompanyLogoContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    relatedCompanyLabel: {
      marginTop: `${theme.spacing(1)} !important`,
      fontWeight: '300 !important',
      width: 80,
      height: 40,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    subBarCount: {
      color: theme.palette.grey[300],
      display: 'inline-block',
      marginLeft: theme.spacing(1),
      verticalAlign: 'middle',
    },
    showAllSourcesLink: {
      color: `${otherContrastColorSets[2]} !important`,
      textDecorationColor: `${otherContrastColorSets[2]} !important`,
      cursor: 'pointer',
      marginBottom: `${theme.spacing(1.5)} !important`,
      display: 'inline-block',
      fontSize: '1.0714rem !important',
    },
    moreCommandLink: {
      display: 'inline-flex',
      alignItems: 'center',
      columnGap: 8,
      color: `${theme.palette.grey[500]} !important`,
      textDecoration: 'none !important',
      textDecorationColor: `${otherColorSets[2]} !important`,
      cursor: 'pointer',
      fontSize: '1rem !important',
      fontWeight: 'normal',
      '&:hover': {
        '& .svgIconStroke': {
          stroke: `${Color(otherColorSets[2]).darken(0.1).toString()} !important`,
        },
        '& .svgIconFill': {
          fill: `${Color(otherColorSets[2]).darken(0.1).toString()} !important`,
        },
      },
    },
    compareOutputPopup: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderRadius: `${theme.spacing(1.5)} !important`,
      borderColor: `${otherColorSets[2]} !important`,
    },
    compareOutputPopupTitle: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(-4),
      top: theme.spacing(-4),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(0.25),
    },
    compareOutputPopupHeader: {
      color: `${otherContrastColorSets[2]} !important`,
      fontWeight: '500 !important',
      fontSize: '1.1rem !important',
      letterSpacing: '1.8px',
      marginBottom: `${theme.spacing(2)} !important`,
    },
    compareOutputItem: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      minHeight: theme.spacing(3.75),
      paddingTop: theme.spacing(1),
      transition: 'all 0.3s ease-out',
      '&:hover': {
        background: '#f5f5fe',
      },
      cursor: 'pointer',
    },
    compareOutputItemName: {
      color: `${theme.palette.grey[900]} !important`,
      fontWeight: 'normal !important',
    },
    compareOutputItemOwner: {
      color: `${theme.palette.grey[300]} !important`,
      fontWeight: 'normal !important',
      letterSpacing: '0.17px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    compareOutputTabs: {
      '& .MuiTab-root': {
        textTransform: 'capitalize !important',
        minWidth: 100,
        boxSizing: 'border-box',
      },
    },
    tableSourceTitle: {
      color: `${theme.palette.grey[300]} !important`,
    },
    faqToggleButton: {
      minWidth: '0 !important',
    },
    faqQuestion: {
      fontSize: '0.9285rem !important',
      fontWeight: '500 !important',
      color: `${theme.palette.grey[900]} !important`,
      textAlign: 'left',
    },
    faqAnswer: {
      marginLeft: `${theme.spacing(1)} !important`,
      fontSize: '0.9rem !important',
      color: 'rgb(80, 80, 80) !important',
      marginBottom: `${theme.spacing(1)} !important`,
    },
    faqTableAnswer: {
      fontSize: '0.9rem !important',
      color: 'rgb(80, 80, 80) !important',
      '& *': {
        fontSize: '0.9rem !important',
        color: 'rgb(80, 80, 80) !important',
      },
    },
    faqListAnswer: {
      fontSize: '0.9rem !important',
      color: 'rgb(80, 80, 80) !important',
      '& *': {
        fontSize: '0.9rem !important',
        color: 'rgb(80, 80, 80) !important',
      },
    },
    sourceHighlight: {
      color: `${otherColorSets[2]} !important`,
      // verticalAlign: 'super',
      fontSize: '0.9rem !important',
      fontWeight: '500 !important',
      cursor: 'pointer',
      width: 18,
      height: 18,
      borderRadius: 4,
      backgroundColor: otherAltLightColorSets[2],
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 0.3s ease-out',
      textDecoration: 'none !important',
      marginBottom: '0 !important',
      minWidth: '18px !important',
      '&:hover:not(.noEvent)': {
        backgroundColor: Color(otherAltLightColorSets[2]).darken(0.25).toString(),
      },
      '&.noEvent': {
        cursor: 'default',
      },
      fontStyle: 'normal !important',
      marginLeft: `${theme.spacing(-0.5)} !important`,
    },
    sourceTitle: {
      display: 'inline-block',
      marginLeft: `${theme.spacing(0.5)} !important`,
    },
    proceedButton: {
      borderRadius: `${theme.spacing(1)} !important`,
      backgroundColor: '#e7e7f6 !important',
      color: `${otherContrastColorSets[2]} !important`,
      '&:hover': {
        backgroundColor: `${Color('#e7e7f6').darken(0.05).toString()} !important`,
      },
      minWidth: '0 !important',
      fontSize: '0.785rem !important',
      width: 70,
      height: 22,
      padding: '4px !important',
      marginRight: `${theme.spacing(0.5)} !important`,
      display: 'flex',
      alignItems: 'center',
      '&.followUpQuestion': {
        width: 22,
      },
    },
    showMoreFaqsLink: {
      display: 'inline-block',
      color: `${theme.palette.grey[100]} !important`,
      textDecoration: 'none !important',
      textDecorationColor: `${theme.palette.grey[100]} !important`,
      '&:hover': {
        textDecoration: 'underline !important',
      },
      cursor: 'pointer',
      marginTop: `${theme.spacing(1)} !important`,
      marginLeft: `${theme.spacing(1)} !important`,
    },
    followUpQuestionsBox: {
      marginTop: theme.spacing(3),
    },
    followUpQuestionsHeader: {
      color: `${theme.palette.grey[500]} !important`,
      fontWeight: '500 !important',
    },
    followUpQuestionItem: {
      display: 'flex',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      cursor: 'pointer',
      transition: 'all 0.3s ease-out',
      '&:hover': {
        backgroundColor: '#e7e7f6 !important',
      },
    },
    followUpQuestionText: {
      color: `${theme.palette.grey[900]} !important`,
      fontWeight: '500 !important',
      fontSize: '1rem !important',
      paddingLeft: theme.spacing(1),
    },
    faqAnswerBox: {
      '&:not(:first-of-type)': {
        marginTop: theme.spacing(3),
      },
    },
  });
};

export default styles;
