/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { v4 } from 'uuid';
import { ExtraProps } from 'react-markdown';

import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Link,
  Tooltip,
} from '@mui/material';

import {
  ContentCopy as CopyIcon,
  BarChart as ChartIcon,
} from '@mui/icons-material';
import classNames from 'classnames';

import { ReactComponent as CodeIcon } from '../../../../assets/svg/codeIcon.svg';

import { PropsFromRedux } from './container';
import styles from './CodeBlockView.styles';

export interface Props extends PropsFromRedux {
  title?: string;
  language?: string;
  isStepContent?: boolean;
  code?: string;
  codeRendererProps?: React.ClassAttributes<HTMLElement>
  & React.HTMLAttributes<HTMLElement> & ExtraProps;
  allowPreview?: boolean;
}

const useStyles = makeStyles(styles);

const CodeBlockView: React.FC<Props> = ({
  language,
  isStepContent,
  triggerNotification,
  code,
  codeRendererProps,
  allowPreview = true,
}: Props) => {
  const classes = useStyles();
  const [hasPreview, setHasPreview] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (language === 'html' && code?.includes('<script>')) {
      setHasPreview(true);
      setIsPreview(true);
    } else {
      setHasPreview(false);
      setIsPreview(false);
    }
  }, [language, code]);

  const copyCodeToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(code || '');
      triggerNotification({
        notification: {
          key: v4(),
          type: 'val',
          payload: 'Code copied to clipboard',
          isDisplayed: false,
          delay: 1000,
        },
      });
    } catch {
      triggerNotification({
        notification: {
          key: v4(),
          type: 'error',
          payload: 'Fail to copy code to clipboard',
          isDisplayed: false,
          delay: 1000,
        },
      });
    }
  };

  return (
    <div>
      <div className={classes.codeBlockHeader}>
        <Grid container>
          <Grid item container alignItems="center" xs={6}>
            <div className="title">
              {language}
            </div>
          </Grid>
          <Grid item container alignItems="center" justifyContent="flex-end" xs={6}>
            {!isStepContent && !isPreview && (
              <Link
                className={classes.copyCodeLink}
                onClick={() => {
                  void copyCodeToClipboard();
                }}
              >
                <CopyIcon fontSize="small" />
                Copy
              </Link>
            )}
            {hasPreview && (
              <Box marginLeft={1}>
                <ButtonGroup
                  variant="outlined"
                  color="info"
                  size="small"
                >
                  <Tooltip
                    title="View Result"
                  >
                    <Button
                      className={
                        classNames(
                          classes.contentModeButtons,
                          isPreview ? 'active' : undefined,
                        )
                      }
                      onClick={() => setIsPreview(true)}
                    >
                      <ChartIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title="View Code"
                  >
                    <Button
                      className={
                        classNames(
                          classes.contentModeButtons,
                          !isPreview ? 'active' : undefined,
                        )
                      }
                      onClick={() => setIsPreview(false)}
                    >
                      <CodeIcon />
                    </Button>
                  </Tooltip>
                </ButtonGroup>
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
      {isPreview && allowPreview ? (
        <iframe
          srcDoc={code}
          width="100%"
          height={400}
          className={classes.previewFrame}
        />
      ) : (
        <SyntaxHighlighter
          {...codeRendererProps}
          ref={undefined}
          PreTag="div"
          wrapLines
          wrapLongLines
          children={code || ''}
          language={language}
          style={materialLight}
          customStyle={{ width: isStepContent ? 700 : 750 }}
        />
      )}
    </div>
  );
};

export default CodeBlockView;
